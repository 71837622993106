import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link, graphql, navigate } from "gatsby"

import Layout from "../components/layout/layout"
import Pagination from "../components/pagination"
import React, { useState, useRef, useEffect } from "react"
import Search from "../components/search"
import Seo from "../components/seo"

const NewsListItem = ({
	item: { permalink, authorizedOn, thumbnailImages, name, excerpt },
}) => {
	const [contentHeight, setContentHeight] = useState("auto")
	const contentRef = useRef(null)

	useEffect(() => {
		if (contentRef.current) {
			setContentHeight(`${contentRef.current.scrollHeight}px`)
		}
	}, [excerpt])

	const linkUrl = `/news/${permalink}`
	const image =
		thumbnailImages &&
		thumbnailImages.childImageSharp &&
		getImage(thumbnailImages.childImageSharp)
	const checkImg = image || image !== 0 ? image : null

	return (
		<div className="bg-white shadow overflow-hidden mt-5 mb-5 ml-10 mr-10">
			<Link to={linkUrl} className="flex">
				{/* Text Column */}
				<div className="flex-1" style={{ minWidth: "81%" }}>
					<div className="px-4 sm:px-6 w-full h-full">
						<div className="pt-4 flex items-center">
							<h4 className="text-lg lg:text-lg font-bold text-neutral">
								{name}
							</h4>
						</div>
						<div className="flex flex-row">
							<div className="flex items-center text-sm text-gray-500">
								<p>
									{new Intl.DateTimeFormat("en-AU", {
										timeZone: "Australia/NSW",
										year: "numeric",
										month: "long",
										day: "2-digit",
									}).format(new Date(authorizedOn))}
								</p>
							</div>
						</div>
						<div className="flex">
							<p
								ref={contentRef}
								className="flex-grow overflow-auto text-base md:text-lg lg:text-xl break-word"
								style={{
									width: "100%",
								}}
								dangerouslySetInnerHTML={{
									__html: excerpt.slice(0, 500 || excerpt.length),
								}}
							></p>
						</div>
					</div>
				</div>

				{/* Image Column */}
				{checkImg && (
					<div className="flex-shrink-0">
						<Link to={linkUrl}>
							<GatsbyImage
								className="h-32 w-full sm:w-32 object-cover"
								image={image}
								alt={`Thumbnail for news item`}
							/>
						</Link>
					</div>
				)}
			</Link>
		</div>
	)
}

const NewsList = ({ pageContext: { currentPage, limit, numPages }, data }) => {
	const pageChanged = pageNumber => {
		navigate(`/news/${pageNumber === 1 ? `` : pageNumber}`)
	}

	const items = data.allNewsItem.nodes

	return (
		<Layout>
			<Seo title="News list" />
			<div className="min-h-screen bg-gray-200 divide-y divide-gray-200">
				<div className="grid grid-cols-1">
					{items.map(item => (
						<NewsListItem key={item.newsId} item={item} className="m-4" />
					))}
				</div>

				<Pagination
					onPageChange={pageChanged}
					pageCount={numPages}
					currentPage={currentPage}
					pageSize={limit}
				/>
			</div>
			{/* <ul role="list" className="divide-y divide-gray-200 ">
				{items.map(item => (
					<li key={item.newsId} className="px-4 py-4 sm:px-0">
						<NewsListItem item={item} />
					</li>
				))}
			</ul> */}
		</Layout>
	)
}
export default NewsList

export const pageQuery = graphql`
	query NewsListQuery($skip: Int!, $limit: Int!) {
		allNewsItem(
			sort: { fields: authorizedOn, order: DESC }
			limit: $limit
			skip: $skip
		) {
			nodes {
				newsId
				permalink
				name
				authorizedOn
				excerpt
				thumbnailImages {
					childImageSharp {
						gatsbyImageData
					}
				}
			}
		}
	}
`
/*
(
		<div className="bg-white shadow flex mt-5 mb-5 ml-10 mr-10 h-48 flex flex-row">
			<div className="w-full ">
				<h4 className=" w-full text-lg lg:text-lg font-bold text-neutral">
					<Link to={linkUrl}>{name}</Link>
				</h4>
				<p className="w-full my-1">
					{new Intl.DateTimeFormat("en-AU", {
						timeZone: "Australia/NSW",
						year: "numeric",
						month: "long",
						day: "2-digit",
					}).format(new Date(authorizedOn))}
				</p>
			</div>
			<div lassName="align-middle h-full overflow-hidden flex">
				<p
					c
					dangerouslySetInnerHTML={{
						__html: excerpt.slice(0, 500 || excerpt.length),
					}}
				></p>
			</div>

			{checkImg && (
				<div className="mb-4 flex-shrink-0 sm:mb-0 sm:mr-4">
					<Link to={linkUrl}>
						<GatsbyImage
							className="h-32 w-full sm:w-32 object-cover"
							image={image}
							alt={`Thumbnail for news item`}
						/>
					</Link>
				</div>
			)}
		</div>
	)
*/
